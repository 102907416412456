import HttpError from 'services/ApiService/HttpError';
import { Attachment } from 'types/entities/Attachment';
import { Employee } from 'types/entities/Employee';
import { JurisdictionItem } from 'types/entities/JurisdictionItem';
import FilterParam from 'types/FilterParam';
import Tokens from 'types/Tokens';

/**
 * Содержит методы для работы с серверным API.
 */
export default class ApiHelper {
  /**
   * Возвращает `true`, если указанное исключение является ошибкой API с
   * указанным статусом ответа и, опционально, текстом сообщения об ошибке.
   * @param error Исключение.
   * @param status Статус (или массив статусов) ответа сервера.
   * @param messages Список подстрок, которые должны встречаться в сообщении
   * об ошибке. Если встретилась хотя бы одна из них, метод возвращает `true`.
   */
  public static isError(
    error: any,
    status: number | number[] | undefined,
    ...messages: string[]
  ) {
    if (!(error instanceof HttpError)) {
      return false;
    }

    if (status == null) {
      return true;
    }

    const statuses = typeof status === 'number' ? [status] : status;

    if (!statuses.includes(error.responseStatus)) {
      return false;
    }

    const { length } = messages;

    if (length === 0) {
      return true;
    }

    if (error.responseType !== 'json') {
      return false;
    }

    if (typeof error.responseData !== 'object') {
      return false;
    }

    const { message: rawActualMessage } = error.responseData;

    if (typeof rawActualMessage !== 'string') {
      return false;
    }

    const actualMessage = rawActualMessage.toLocaleLowerCase();

    for (let i = 0; i < length; i += 1) {
      const expectedMessage = messages[i].toLocaleLowerCase();

      const isMatch = actualMessage.indexOf(expectedMessage) >= 0;

      if (isMatch) {
        return true;
      }
    }

    return false;
  }

  /**
   * Возвращает пару токенов авторизации из указанного ответа API.
   * @param response Ответ сервера.
   */
  public static parseTokens(response: any) {
    const tokens: Tokens = {
      refresh: String(response.refresh_token),
      access: String(response.access_token),
    };

    return tokens;
  }

  /**
   * Забирает массив параметров  фильтрации и преобразуем в объект,
   * который потом можно положить как data в get-запрос
   * @param filterParams массив параметров фильтрации
   */
  public static remapFilterParams(filterParams: FilterParam[]) {
    if (filterParams.length) {
      let filterOptions = {};

      filterParams.forEach(({ type, value }) => {
        const key = !Array.isArray(value) ? type : `${type}[]`;

        filterOptions = { ...filterOptions, [key]: value };
      });

      return filterOptions;
    }

    return null;
  }

  public static mapEmployee(response: any): Employee {
    return {
      id: response.uuid,
      firstName: response.first_name,
      patronymicName: response.patronymic,
      lastName: response.last_name,
      avatar: undefined,
      roles: response.roles,
    };
  }

  /**
   * Возвращает данные по истории договора, приведённые к нашей модели.
   * @param response Ответ API.
   */
  public static mapContractHistory(response: any) {
    const list = response.payload;

    const remappedHistory: any[] = list.map((history: any) => ({
      id: String(history.uuid),
      loan: String(history.number),
      employee: history.employee ? this.mapEmployee(history.employee) : null,
      type: String(history.type),
      title: history.title ? String(history.title) : '',
      comment: history.comment ? String(history.comment) : '',
      created_at: new Date(history.created_at),
    }));

    return remappedHistory;
  }

  /**
   * Возвращает данные по определению подсудности, приведённые к нашей модели.
   * @param response Ответ API.
   */
  public static mapContractJurisdictionLastQuery(
    response: any,
  ): JurisdictionItem | undefined {
    const item = response?.payload?.at(-1);

    if (!item) {
      return undefined;
    }

    return {
      id: item.uuid,
      contractID: item.loan_uuid,
      createdAt: new Date(item.created_at),
      finishedAt: item.finished_at ? new Date(item.finished_at) : undefined,
      startedAt: item.started_at ? new Date(item.started_at) : undefined,
      status: item.status,
      message: item.message,
    };
  }

  /**
   * Возвращает данные о вложенном файле.
   */
  public static mapAttachment(response: any): Attachment {
    return {
      id: response.uuid,
      contractID: response.loan_uuid,
      name: response.name,
      date: new Date(response.created_at),
      size: Number(response.size),
      type: response.type,
      url: response.url,
      downloadUrl: response.download_url,
      extension: response.extension,

      owner: response.owner
        ? {
            id: response.owner.uuid,
            firstName: response.owner.first_name,
            patronymicName: response.owner.patronymic,
            lastName: response.owner.last_name,
          }
        : undefined,
    };
  }
}
