import SessionExpiredDialog from 'components/dialogs/SessionExpiredDialog';
import UnauthorizedUserDialog from 'components/dialogs/UnauthorizedUserDialog';
import StorageKey from 'constants/StorageKey';
import useLocalStorage from 'hooks/useLocalStorage';
import React, {
  createContext,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ApiService from 'services/ApiService';
import { User, UserRole } from 'types/entities/User';
import Tokens from 'types/Tokens';
/**
 * Значение контекста сессии.
 */
type SessionContextValue = Readonly<{
  /**
   * Определяет, инициализирован ли контекст сессии.
   */
  readonly isReady: boolean;

  /**
   * Определяет, прошёл ли пользователь аутентификацию.
   */
  readonly isAuthenticated: boolean;

  /**
   * Определяет, происходит ли процедура выхода из системы.
   */
  readonly isLoggingOut: boolean;

  /**
   * Определяет, является ли пользователь администратором.
   */
  readonly isAdmin: boolean;

  /**
   * Сервис клиента API.
   */
  readonly client: ApiService;

  /**
   * Текущий пользователь.
   */
  readonly user: User | undefined | null;

  /**
   * Авторизует контекст сессии для использования токенов аутентификации.
   */
  authorize(tokens: Tokens): void;

  /**
   * Уничтожает контейнер защищённой сессии, завершая её.
   */
  logout(): void;
}>;

/**
 * Контекст контейнера сессии.
 */
const SessionContext = createContext<SessionContextValue>(
  undefined! as SessionContextValue,
);

/**
 * Возвращает провайдер контекста контейнера сессии.
 */
export const SessionContextProvider: FC = ({ children }) => {
  const tokens = useRef<Tokens>();
  const [initialized, setInitialized] = useState(false);
  const [expired, setExpired] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const queryClient = useQueryClient();

  const [accessToken, setAccessToken] = useLocalStorage<string>(
    StorageKey.ACCESS_TOKEN,
  );
  const [refreshToken, setRefreshToken] = useLocalStorage<string>(
    StorageKey.REFRESH_TOKEN,
  );

  useEffect(() => {
    tokens.current =
      accessToken && refreshToken
        ? {
            access: accessToken,
            refresh: refreshToken,
          }
        : undefined;

    setInitialized(true);
  }, [accessToken, refreshToken]);

  const service = useRef(
    new ApiService({
      getTokens: () => tokens.current,
      onTokensChange: (newTokens) => {
        tokens.current = newTokens;
        setAccessToken(newTokens.access);
        setRefreshToken(newTokens.refresh);
      },
      onTokensExpire: () => {
        tokens.current = undefined;

        setAccessToken();
        setRefreshToken();

        setExpired(true);
      },
    }),
  );

  const { data: user, refetch } = useQuery(
    'user',
    () => service.current.fetchUserInfo(),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(tokens.current) && initialized,
    },
  );

  useEffect(() => {
    if (user === null) {
      setUnauthorized(true);
    }
  }, [user]);

  const authenticated = !loggedOut && initialized && Boolean(tokens.current);

  function handleCloseExpired() {
    setExpired(false);
  }

  function handleCloseUnauthorized() {
    value.logout();
  }

  const value = useMemo<SessionContextValue>(
    () => ({
      isReady: authenticated ? Boolean(user) : initialized,
      isAuthenticated: authenticated,
      isLoggingOut: loggedOut,
      isAdmin:
        user?.role === UserRole.Owner ||
        user?.role === UserRole.Admin ||
        user?.role === UserRole.ElectroCourt ||
        user?.role === UserRole.Court,
      client: service.current,
      user,

      authorize(newTokens) {
        setAccessToken(newTokens.access);
        setRefreshToken(newTokens.refresh);

        tokens.current = newTokens;
        refetch();

        setLoggedOut(false);
      },

      logout() {
        setUnauthorized(false);
        setAccessToken();
        setRefreshToken();
        tokens.current = undefined;

        // Invalidate all the client cache due to client logging out.
        queryClient.clear();

        setLoggedOut(true);
      },
    }),
    [
      authenticated,
      initialized,
      loggedOut,
      user,
      refetch,
      queryClient,
      setAccessToken,
      setRefreshToken,
    ],
  );

  return (
    <SessionContext.Provider value={value}>
      <SessionExpiredDialog active={expired} onClose={handleCloseExpired} />
      <UnauthorizedUserDialog
        active={unauthorized}
        onClose={handleCloseUnauthorized}
      />
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContext;
