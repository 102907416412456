/**
 * Текст всплывающего уведомления.
 */
export enum ToastText {
  /**
   * Текст сообщения об успешной смене пароля.
   */
  PasswordChanged = 'Пароль успешно изменен',

  /**
   * Текст сообщения об истёкшей сессии.
   */
  SessionExpired = 'Текущая сессия истекла',

  /**
   * Отображается, когда пользователь ввёл неверный код подтверждения.
   */
  BadConfirmationCode = 'Неверный код подтверждения',

  /**
   * Текст сообщения об успешном запуске проверки на банкротство.
   */
  BankruptcyCheckStarted = 'Запущена проверка банкротства',

  /**
   * Текст сообщения об успешном запуске определения подсудности.
   */
  CourtSearchStarted = 'Запущено определение подсудности',

  /**
   * Текст сообщения об успешном запуске проверки судебного решения.
   */
  CourtJudgmentCheckStarted = 'Проверка судебного решения добавлена в очередь',

  /**
   * Текст сообщения об успешном запуске расчета задолженности.
   */
  DebtCalculationStarted = 'Запущен расчет долга',

  /**
   * Текст сообщения об успешном запуске формирования шаблонов в суд.
   */
  DocumentsCreationStarted = 'Запущено формирование шаблонов документов',

  /**
   * Текст сообщения об успешном запуске отправки документов в суд.
   */
  DocumentsSendingStarted = 'Отправка документов в суд добавлена в очередь',

  /**
   * Текст сообщения об успешном запуске запроса оплаты ГП.
   */
  StateFeePaymentStarted = 'Запущена оплата ГП',

  /**
   * Текст сообщения об успешном запуске создания файла для печати.
   */
  PrintFileCreationStarted = 'Запущено создание файла для печати',

  /**
   * Текст сообщения об успешном запуске создания архива файлов для печати.
   */
  PrintArchiveCreationStarted = 'Запущено создание архива файла для печати',

  /**
   * Текст сообщения о смене статуса.
   */
  StatusChangeStarted = 'Запущена смена статуса договора',

  /**
   * Текст сообщения о смене ответственного.
   */
  AssigneeChangeStarted = 'Запущена смена ответственного',

  /**
   * Текст сообщения об успешном запуске проверки судебного решения.
   */
  BulkJudgmentCheckStarted = 'Проверки судебных решений добавлены в очередь',

  /**
   * Текст сообщения об успешном запуске отправки документов в суд.
   */
  BulkDocumentsSendingStarted = 'Отправки документов в суд добавлены в очередь',

  /**
   * Текст сообщения при достижении лимита отправок кода подтверждения.
   */
  ConfirmationTriesExceeded = 'Превышено максимальное количество попыток отправки кода подтверждения',

  /**
   * Текст при сохранении любого редактируемого поля.
   */
  FieldValueSaved = 'Значение сохранено',

  /**
   * Текст при ошибке сохранения любого редактируемого поля.
   */
  FieldValueNotSaved = 'Ошибка. Значение не сохранено',

  /**
   * Текст при ошибке удаления файла
   */
  AttachmentNotRemoved = 'Ошибка. Документ не удален',

  /**
   * Текст при успешном удалении файла
   */
  AttachmentRemoved = 'Документ успешно удален',

  /**
   * Текст при успешном действии.
   */
  ActionCompleted = 'Действие успешно завершено',

  /**
   * Текст при успешном сохранении настроек.
   */
  SettingsSaved = 'Настройки сохранены',

  /**
   * Текст при неуспешном сохранении настроек.
   */
  SettingsNotSaved = 'Ошибка при сохранении настроек',

  /**
   * Текст по результату формирования архива файлов для печати.
   */
  PrintArchiveReady = 'Архив файлов для печати сформирован',

  /**
   * Текст об успешном запуске задачи.
   */
  TaskStarted = 'Задача успешно запущена',

  /**
   * Текст о запуске формирования отчёта по реестру 1С УЗ.
   */
  RegistryReportGenerationStarted = 'Запущено формирование отчета по реестру 1С УЗ',

  /**
   * Текст о запуске формирования реестра ГП.
   */
  StateDutyRegistryGenerationStarted = 'Запущено формирование реестра для банка',

  /**
   * Текст об успешной замене комментариев СУ.
   */
  CourtCommentReplaceSuccess = 'Комментарии СУ изменены',

  /**
   * Текст об ошибке при замене комментариев СУ.
   */
  CourtCommentReplaceFailed = 'Ошибка при замене комментариев СУ',

  /**
   * Текст сообщения об успешном запуске создания архива ЭС.
   */
  ArchiveECCreationStarted = 'Запущено создание архива ЭС',

  /**
   * Текст сообщения об успешном запуске отправки ЭС партнеру.
   */
  ECSendStarted = 'Запущена отправка партнеру',

  /**
   * Текст сообщения об успешном запуске формирования уведомления о суде.
   */
  CourtNoticeGenerationStarted = 'Запущено формирование уведомления о суде',

  /**
   * Текст сообщения об успешном запуске формирования файлов для печати документов должника.
   */
  DebtorCreateFilesStarted = 'Запущено формирование файлов для печати документов должников',

  /**
   * Текст сообщения об успешном запуске формировании архива на должника.
   */
  DebtorCreateArchiveStarted = 'Запущено формирование архива на должников',
}

/**
 * Тип всплывающего уведомления.
 */
export enum ToastType {
  /**
   * Сообщение об успехе.
   */
  Success = 'success',

  /**
   * Ошибка.
   */
  Error = 'error',
}

/**
 * Всплывающее уведомление.
 */
export interface Toast {
  /**
   * Уникальный идентификатор уведомления.
   */
  id: string;

  /**
   * Указывает, что модель в данный момент должна отображаться. Флаг
   * используется, чтобы скрытие уведомлений происходило плавно.
   */
  open: boolean;

  /**
   * Тип уведомления.
   */
  type: ToastType;

  /**
   * Текст уведомления.
   */
  text: ToastText;

  /**
   * Ссылка "Подробнее" в уведомлении.
   */
  moreLink?: string;
}
