import StorageKey from 'constants/StorageKey';
import useLocalStorage from 'hooks/useLocalStorage';
import { createContext, FC, useEffect, useState } from 'react';

/**
 * Значение контекста уведомлений.
 */
type NotificationsContextValue = Readonly<{
  /**
   * Коллекция идентификаторов "просмотренных" уведомлений.
   */
  items: string[];

  /**
   * Возвращает является ли задача "просмотренной".
   * @param taskID Идентификатор задачи.
   */
  isRead(taskID: string): boolean;

  /**
   * Помечает задачу как "просмотренную".
   * @param taskID Идентификатор задачи.
   */
  markRead(taskID: string): void;
}>;

/**
 * Контекст контейнера всплывающих сообщений.
 */
const NotificationsContext = createContext<NotificationsContextValue>(
  undefined! as NotificationsContextValue,
);

/**
 * Возвращает провайдер контекста контейнера уведомлений.
 */
export const NotificationsContextProvider: FC = ({ children }) => {
  const [items, setItems] = useState<string[]>([]);
  const [storageValue, setStorageValue] = useLocalStorage(
    StorageKey.VIEWED_TASKS,
  );

  useEffect(() => {
    if (storageValue) {
      const parsedValue = storageValue;
      const inboundValue: string[] =
        Array.isArray(parsedValue) &&
        parsedValue.every((value) => typeof value === 'string')
          ? parsedValue
          : [];

      setItems(inboundValue);
    }
  }, [storageValue]);

  function isRead(taskID: string) {
    return items.includes(taskID);
  }

  function markRead(taskID: string) {
    const newItems = [...items.filter((item) => item !== taskID), taskID];

    setStorageValue(newItems.slice(-10));

    return {
      items: newItems,
    };
  }

  const state = {
    items,
    isRead,
    markRead,
  };

  return (
    <NotificationsContext.Provider value={state}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
