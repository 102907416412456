import React, { FC } from 'react';

import { Typography } from '@material-ui/core';

import Button from '../ui/Button';
import Dialog from '../ui/Dialog';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Признак активно ли диалоговое окно.
   */
  active: boolean;

  /**
   * Обработчик события закрытия диалогового окна.
   */
  onClose?: () => void;
};

/**
 * Отображает диалог, сообщающий о неизвестном пользователе.
 */
const UnauthorizedUserDialog: FC<Props> = ({ active, onClose }) => (
  <Dialog
    open={active}
    disableEscapeKeyDown
    title="Сообщение"
    maxWidth="xs"
    fullWidth
    actions={
      <>
        <Button variant="text" color="primary" onClick={onClose}>
          Войти
        </Button>
      </>
    }
  >
    <Typography variant="subtitle2">Пользователь не найден</Typography>
    <Typography variant="body2">
      Пожалуйста, зайдите под другим логином
    </Typography>
  </Dialog>
);

export default UnauthorizedUserDialog;
