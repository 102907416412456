/**
 * Роль пользователя.
 */
export enum UserRole {
  /**
   * Владелец системы, он же суперадмин.
   */
  Owner = 'owner',

  /**
   * Администратор.
   */
  Admin = 'admin',

  /**
   * Рядовой сотрудник.
   */
  User = 'user',

  /**
   * Менеджер судебного судопроизводства.
   */
  Court = 'court',

  /**
   * Менеджер системы.
   */
  Manager = 'manager',

  /**
   * Работник с электронными заявками в суды.
   */
  ElectroCourt = 'electro_court',
}

/**
 * Данные о текущем пользователе.
 */
export interface User {
  /**
   * Уникальный идентификатор сотрудника.
   */
  id: string;

  /**
   * Отчество пользователя.
   */
  patronymicName: string;

  /**
   * Имя пользователя.
   */
  firstName: string;

  /**
   * Фамилия пользователя.
   */
  lastName: string;

  /**
   * Аватар пользователя.
   */
  avatar: string;

  /**
   * Роль пользователя.
   */
  role: UserRole;
}
