/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import StorageKey from 'constants/StorageKey';

/*
 * Возвращает управляющие элементы задачи определённого типа.
 * @param key Ключ localStorage.
 * @param fallbackValue Значение по умолчанию для ключа.
 */
export default function useLocalStorage<T>(key: StorageKey, defaultValue?: T) {
  const [data, setData] = useState<T | undefined>(() => {
    if (typeof localStorage === 'undefined') {
      return defaultValue;
    }

    const loadedData = localStorage.getItem(key);

    if (loadedData) {
      let parsedValue: any;

      // old values saved as strings fallback
      try {
        parsedValue = JSON.parse(loadedData);
      } catch (e) {
        parsedValue = loadedData;
      }

      return parsedValue;
    }
    if (defaultValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
    }

    return defaultValue;
  });

  function setLocalData(value?: T) {
    setData(value);

    if (typeof localStorage === 'undefined') {
      return;
    }

    if (typeof value === 'undefined') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  return [data, setLocalData] as const;
}
